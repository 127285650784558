import React from 'react'
import tw from 'twin.macro'
//
import mappedParagraphs from '~components/paragraphs'

const NotImplemented = ({ name }) => (
  <h3 css={tw`px-4 py-1 border border-c-utils-generic-border border-dotted m-2 text-c-utils-generic-text`}>
    {name} - Not implemented yet
  </h3>
)

export const ParagraphComponents = ({ paragraphs, language, hasSidebar = false, isSidebar = false }) => (
  <>
    {paragraphs
      .map((paragraph) => paragraph.entity)
      .filter((entity) => entity?.__typename)
      .map((entity, idx) => {
        if (!Object.hasOwnProperty.call(mappedParagraphs, entity.__typename)) {
          return <NotImplemented key={`${entity.id}-${idx}`} name={entity.__typename} />
        }

        const ParagraphComponent = mappedParagraphs[entity.__typename]

        return (
          <ParagraphComponent
            key={`${entity.id}-${idx}-${language}`}
            {...entity}
            hasSidebar={hasSidebar}
            isSidebar={isSidebar}
            language={language}
          />
        )
      })}
  </>
)
