import React from 'react'
//
import Menu from '~storybook/menu'

const ParagraphMenu = ({ fieldHeading, fieldHeadingType, fieldMenu, fieldLink = [] }) => {
  //const links = fieldLink?.map((link) => file?.entity)

  return <Menu heading={fieldHeading} headingType={fieldHeadingType} links={fieldLink} menu={fieldMenu} />
}

export default ParagraphMenu